import React, { useState } from 'react'

import { connect } from 'react-redux'
import moment from 'moment'
import classnames from 'classnames'

import * as invoicesActions from '../../../redux/actions/invoice'

import classes from './styles.module.scss'
import lightningIcon from '../../../assets/images/lightningMin.svg'
import lightningIconBlue from '../../../assets/images/lightningMinBlue.svg'

function CompanyCardGroup ({ invoice, addInvoiceToPay, removeInvoiceToPay }) {
  const [selected, setSelected] = useState(false)

  const toggleInvoiceSelection = () => {
    if (!selected) {
      setSelected(true)
      addInvoiceToPay(invoice)
    } else {
      setSelected(false)
      removeInvoiceToPay(invoice)
    }
  }

  return (
    <div
      className={
        selected
          ? classnames(classes.CompanyCard, classes.borderCard)
          : classes.CompanyCard
      }
      onClick={toggleInvoiceSelection}
    >
      <div className={classes.boxImg}>
        {selected
          ? (
          <img src={lightningIconBlue} alt="LightningBlue" />
            )
          : (
          <img src={lightningIcon} alt="Lightning" />
            )}
      </div>
      <div className={classes.companyInfo}>
        <div className={classes.inmubleBox}>
          <span className={classes.inmuble}>Inmuble: </span>
          <span className={classes.inmubleValue}>{invoice.INMUEBLE}</span>
        </div>
        <div className={classes.contactBox}>
          <span className={classes.label}>Factura: </span>
          <span className={classes.value}>
            {invoice.FACTURA || invoice.CONTRATO}
          </span>
        </div>
        <div className={classes.contactBox}>
          <span className={classes.label}>Fecha: </span>
          <span className={classes.value}>
            {moment(invoice.FECHA_FAC).format('MMM/DD/YY')}
          </span>
        </div>
        <div className={classes.contactBox}>
          <span className={classes.label}>Titular: </span>
          <span className={classes.value}>{invoice.NOMBRE}</span>
        </div>
        <div className={classes.amountBoxMobile}>
          <span className={classes.amount}>Amount:</span>
          <span className={classes.amountValue}>
            ${parseFloat(invoice.SALDO).toLocaleString('en-US')} USD
          </span>
          <span className={classes.amountValue}>
            ${parseFloat(invoice.SALDODOP).toLocaleString('en-US')} DOP
          </span>
        </div>
      </div>
      <div className={classes.amountBox}>
        <span className={classes.amount}>Amount:</span>
        <span className={classes.amountValue}>
          ${parseFloat(invoice.SALDO).toLocaleString('en-US')} USD
        </span>
        <span className={classes.amountValue}>
          ${parseFloat(invoice.SALDODOP).toLocaleString('en-US')} DOP
        </span>
      </div>
    </div>
  )
}

export default connect(null, {
  addInvoiceToPay: invoicesActions.addInvoiceToPay,
  removeInvoiceToPay: invoicesActions.removeInvoiceToPay
})(CompanyCardGroup)
